import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './NavigatorPath.scss';
import Arrow2 from '../../Images/Arrow.webp'

// import { useLocation } from "react-router-dom";


export default function NavigatorPath({  }) {
    const location = useLocation()
    const [NavigatorPages, setNavigatorPages] = useState(location.pathname.split('/'));

    let textt = ""
    NavigatorPages.slice(1).map((A, index) => {
        index == NavigatorPages.slice(1).length - 1 ?
            textt += A + ''
            :
            textt += A + '/'
    })

    return (
        <div className="NavigatorPath">
            <div className="NavigatorPathBox" >
                <a href={`/`}>Huntington Beach Garage Door</a>
                <img src={Arrow2} alt="" />
            </div>
            {NavigatorPages.slice(1).map((a, index) =>
                <div key={index} className="NavigatorPathBox">
                    <a
                        href={`/${index == 0 ? a : index == 1 ? textt : null}`}
                        style={NavigatorPages.length - 3 == index ? null : { "textDecoration": "unset", "fontWeight": "300", "cursor": "default" }} >
                        {
                            a.indexOf('_') > -1 ?
                                a.slice(0, -1).replace(/_/g, " ").split(' ')
                                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                                    .join(' ')
                                :
                                a.split(' ')
                                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                                    .join(' ')
                        }
                    </a>
                    {NavigatorPages.length - 2 == index ? null : <img src={Arrow2} alt="" />}
                </div>
            )}

        </div>
    )
}
